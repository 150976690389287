import React, { useEffect, useState, useCallback } from 'react';
import Header from '../../components/common/global-top-bar';
import ReviewPageControls, { FilterOptionsType, FilterType } from './common/review-page-controls';
import { connect, useDispatch } from 'react-redux';
import {
  fetchListenerCountRequest,
  fetchListenersRequest,
  updateListenerDetailsRequest,
} from './listeners-redux/actions';
import { ListenerDataType, CountResponseType } from './common/types';
import Spinner from '../../components/common/spinner';
import { DropDown } from '../../components/common';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from './common/listener-review-constants';
import { useHistory } from 'react-router';
import DOMPurify from 'dompurify';

const aboutMeOptions: FilterOptionsType = {
  pending: 'Needs Approval',
  approved: 'Approved',
  rejected: 'Rejected',
  all: 'All',
};

type HandleSaveType = (userId: number, aboutMeStatus: string) => void;

interface TableRowProps {
  aboutMe: string;
  proposedAboutMe: string;
  aboutMeRejected: boolean;
  firstName: string;
  lastName: string;
  displayName: string;
  userId: number;
  listenerId: number;
  handleSave: HandleSaveType;
}

const TableRow: React.FunctionComponent<TableRowProps> = ({
  aboutMe,
  proposedAboutMe,
  aboutMeRejected,
  firstName,
  lastName,
  displayName,
  userId,
  listenerId,
  handleSave,
}): JSX.Element => {
  const history = useHistory();
  const [aboutMeApprovalStatus, setAboutMeApprovalStatus] = useState<string>();
  const purifiedProposedAboutMe = DOMPurify.sanitize(proposedAboutMe, { ALLOWED_TAGS: ['br'] });
  const purifiedAboutMe = DOMPurify.sanitize(aboutMe, { ALLOWED_TAGS: ['br'] });

  const evaluateAboutMeState = (): string => {
    if (aboutMeRejected) {
      return 'rejected';
    } else if (proposedAboutMe || !aboutMe) {
      return 'pending';
    } else {
      return 'approved';
    }
  };

  useState(() => {
    if (userId && !aboutMeApprovalStatus) {
      setAboutMeApprovalStatus(evaluateAboutMeState());
    }
  });

  return (
    <tr className="text-left w-full px-7 border-b align-top">
      <td className="align-top"></td>
      <td className="align-top">
        <div>
          {firstName} {lastName}
        </div>
        <div>aka {displayName ? displayName : <i className="text-gray-500">--</i>}</div>
      </td>
      <td className="align-top">
        <div dangerouslySetInnerHTML={{ __html: purifiedProposedAboutMe || '<i>No proposed about me</i>' }} />
      </td>
      <td className="align-top">
        <div className="flex">
          <div>
            <DropDown
              Label=""
              value={aboutMeApprovalStatus}
              setValue={(data) => {
                setAboutMeApprovalStatus(data);
              }}
            >
              {Object.keys(aboutMeOptions).map((value: string) => (
                <option key={value} value={value}>
                  {aboutMeOptions[value]}
                </option>
              ))}
            </DropDown>
          </div>
          <div className="pl-5">
            <button
              type="button"
              className="bg-bright-blue rounded-lg py-1 px-3 text-white w-auto"
              onClick={() => {
                if (aboutMeApprovalStatus) handleSave(userId, aboutMeApprovalStatus);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </td>
      <td className="py-4 align-top">
        <div dangerouslySetInnerHTML={{ __html: purifiedAboutMe || '<i>No approved about me</i>' }} />
      </td>
      <td className="align-top">
        <button
          onClick={() => {
            history.push(`/listeners/profile/?listenerRoleId=${listenerId}&listenerId=${userId}`);
          }}
        >
          {userId}
        </button>
      </td>
    </tr>
  );
};

type AboutMeReviewProps = {
  listeners: ListenerDataType[];
  listenersCount: CountResponseType;
  pending: boolean;
};

type AboutMeFilterType = {
  page: number;
  limit: number;
  search: string;
  about_me_status: string | null;
  hide_incomplete_peers: boolean;
};

const AboutMeReview: React.FunctionComponent<AboutMeReviewProps> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<AboutMeFilterType>({
    page: 1,
    limit: 25,
    search: '',
    about_me_status: 'pending',
    hide_incomplete_peers: true,
  });

  const updateFilter = useCallback(
    (filter: FilterType) => {
      setFilters((prev: AboutMeFilterType) => {
        return {
          page: filter.page,
          limit: filter.limit,
          search: filter.search,
          about_me_status: filter.filterValue !== 'all' ? filter.filterValue : null,
          hide_incomplete_peers: true,
        };
      });
    },
    [setFilters],
  );

  const fetchData = useCallback(
    (requestPayload: AboutMeFilterType) => {
      dispatch(fetchListenersRequest(requestPayload));
      dispatch(fetchListenerCountRequest(requestPayload));
    },
    [dispatch],
  );

  useEffect(() => {
    if (filters) {
      fetchData(filters);
    }
  }, [fetchData, filters]);

  const getSelectedUserData = (userId: number) => {
    return props.listeners?.find((listener: any) => {
      return listener.user.id === userId;
    });
  };

  const handleSaveRejected = (userId: number) => {
    const payload = {
      listener_role: { about_me_rejected: true },
    };
    dispatch(updateListenerDetailsRequest({ id: userId, payload }));
    if (filters) {
      fetchData(filters);
    }
  };

  const handleSaveApprove = (userId: number) => {
    const selectedUserData = getSelectedUserData(userId);
    const payload = {
      listener_role: { about_me: selectedUserData?.proposed_about_me || '' },
    };
    dispatch(updateListenerDetailsRequest({ id: userId, payload }));
    if (filters) {
      fetchData(filters);
    }
  };

  const handleSavePending = (userId: number) => {
    const selectedUserData = getSelectedUserData(userId);
    const payload = {
      listener_role: { proposed_about_me: selectedUserData?.about_me || '' },
    };
    dispatch(updateListenerDetailsRequest({ id: userId, payload }));
    if (filters) {
      fetchData(filters);
    }
  };

  const handleSave: HandleSaveType = (userId, aboutMeStatus) => {
    switch (aboutMeStatus) {
      case 'approved':
        handleSaveApprove(userId);
        break;
      case 'pending':
        handleSavePending(userId);
        break;
      case 'rejected':
        handleSaveRejected(userId);
        break;
    }
  };

  return (
    <div>
      {props.pending && <Spinner />}
      <Header heading={'About Me'} />
      <SwitchBar heading={HeaderOptions} position={3} />
      <ReviewPageControls
        updateFilter={updateFilter}
        count={props.listenersCount?.count}
        filterOptions={aboutMeOptions}
        defaultFilterValue="pending"
      />

      <div className="max-window-height overflow-y-auto px-7">
        <div className="table-overflow">
          <table className="table-fixed table-row-format w-full relative border-collapse">
            <thead className="bg-white h-full">
              <tr className="text-left w-full px-7">
                <th className="sticky top-0 bg-white h-full mb-4 text-left w-8"></th>
                <th className="py-2 sticky top-0 bg-white h-full mb-4 w-64">Name</th>
                <th className="sticky top-0 bg-white h-full mb-4 text-left w-80">Proposed</th>

                <th className="sticky top-0 bg-white h-full mb-4 text-left w-80">Action</th>
                <th className="sticky top-0 bg-white h-full mb-4 w-80">Current</th>
                <th className="sticky top-0 bg-white h-full mb-4 w-15">Peer ID</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2"></tr>
              {props.listeners?.length > 0 &&
                props.listeners.map((listenerData: any) => (
                  <TableRow
                    key={listenerData?.id}
                    aboutMe={listenerData?.about_me || ''}
                    proposedAboutMe={listenerData?.proposed_about_me || ''}
                    aboutMeRejected={listenerData?.about_me_rejected || ''}
                    lastName={listenerData?.user?.last_name || ''}
                    firstName={listenerData?.user?.first_name || ''}
                    displayName={listenerData?.user.display_name}
                    userId={listenerData?.user.id}
                    listenerId={listenerData?.id}
                    handleSave={handleSave}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    listeners: state.Listeners?.listeners?.data || null,
    listenersCount: state.Listeners?.listenersCount?.data || null,
    pending: state.Listeners?.pending || false,
  };
};

export default connect(mapStateToProps)(AboutMeReview);
