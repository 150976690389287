import Rollbar from 'rollbar';

export const getRollBarEnvironment = () => {
  if (process.env.REACT_APP_ENVNAME === 'dev') {
      return 'development';
    } else if (process.env.REACT_APP_ENVNAME === 'stage') {
      return 'staging';
    } else if (process.env.REACT_APP_ENVNAME === 'prod') {
      return 'production';
    }
};

const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: getRollBarEnvironment(),
  enabled: !(process.env.REACT_APP_ROLLBAR_ENABLED === "false")
});

export default rollbar;
