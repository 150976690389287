import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/common/global-top-bar';
import { CustomInput, DropDown, ToggleButton } from '../../components/common';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from './clients-constants';
import SaveButton from './switch-bar-button';
import HeadBack from './redirect';
import { useDispatch, connect } from 'react-redux';
// @ts-ignore
import { convertToRaw, EditorState, ContentState } from 'draft-js';
// @ts-ignore
import draftToHtml from 'draftjs-to-html';
//@ts-ignore
import htmlToDraft from 'html-to-draftjs';
import {
  fetchTagsRequest,
  getClientDetailsRequest,
  getClientDetailsSuccess,
  postCreateClientRequest,
  postCreateClientSuccess,
  postUpdateClientSuccess,
} from './clients-redux/actions';
import Spinner from '../../components/common/spinner';
import RichTextEditor from '../../components/common/rich-text-editor';
import { toast } from 'react-toastify';
import RouteLeavingGuard from '../../components/route-leaving-prompt';
import { ROUTE_PATH } from '../../routes/paths';
import Modal from '../../components/common/modal';
import { useUpdateClient } from '../../hooks/useClients';
import { Button } from '@kindlyhuman/component-library';
import { useSyncHubspot } from '../../hooks/useClients';

type props = {
  client: any;
  pending: any;
  tags: any;
  createClient: any;
  updateClient: any;
};

const clientStatus = [
  { label: 'INACTIVE', key: 'inactive' },
  { label: 'SETUP', key: 'setup' },
  { label: 'LIVE', key: 'active' },
];

const Configure: React.FunctionComponent<props> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mutateSyncHubspot = useSyncHubspot();

  const [ClientName, setClientName] = useState('');
  const [resellerName, setResellerName] = useState('');
  const [contractDate, setContractDate] = useState<string | null>(null);
  const [goLiveDate, setGoLiveDate] = useState<string | null>(null);
  const [isTest, setIsTest] = useState(false);
  const [image, setImgPreview] = useState<any>();
  const [groupLogoImage, setGroupLogoImage] = useState<any>();
  const [groupLogoOverlayImage, setGroupLogoOverlayImage] = useState<any>();
  const [internalNotes, setinternalNotes] = useState('');
  const [preloadedImage, setPreloadedImage] = useState(false);
  const [preLoadedLogoImage, setPreLoadedLogoImage] = useState(false);
  const [preLoadedLogoOverImage, setPreLoadedLogoOverImage] = useState(false);
  const [richText, setrichText] = useState<any>();
  const [tagstaus, settagstaus] = useState<any>([]);
  const [clientCode, setClientCode] = useState<string | null>(null);
  const [industryVerticalCategory, setIndustryVerticalCategory] = useState<string | null>('');

  const [active, setactive] = useState('setup');
  const [error, seterror] = useState(false);
  const clientId = localStorage.getItem('clientid');
  const { updateClient } = useUpdateClient(parseInt(clientId!));

  const [changeToken, setchangeToken] = useState(false);
  const [LandingPromo, setLandingPromo] = useState<boolean>();
  const [enableEligibility, setenableEligibility] = useState(false);
  const [memberSelfEnroll, setMemberSelfEnroll] = useState(false);
  const [crmSync, setCrmSync] = useState(false);
  const [contractIncludesComms, setContractIncludesComms] = useState(false);

  const [PromotionType, setPromotionType] = useState('image');
  const [PromotionVideo, setPromotionVideo] = useState<any>();
  const [PromotionImage, setPromotionImage] = useState<any>();
  const [BackgroundLogoImage, setBackgroundLogoImage] = useState<any>();
  const [preLoadedBackgroundLogoImage, setpreLoadedBackgroundLogoImage] = useState(false);

  const [preLoadedPoweredByHeaderImage, setPreLoadedPoweredByHeaderImage] = useState<any>();
  const [poweredByHeaderImage, setPoweredByHeaderImage] = useState<any>();
  const [poweredByHeaderEnabled, setPoweredByHeaderEnabled] = useState<boolean>(false);

  const [groupBannerImage, setGroupBannerImage] = useState<string | null>();
  const [preLoadedGroupBanner, setPreLoadedGroupBanner] = useState<any>();
  const [groupBannerEnabled, setGroupBannerEnabled] = useState<boolean>(false);

  const [preLoadedWelcomeImage, setpreLoadedWelcomeImage] = useState(true);
  const [preLoadedAuthorImage, setpreLoadedAuthorImage] = useState(true);
  const [preLoadedWelcomeVideo, setpreLoadedWelcomeVideo] = useState(true);
  const [preLoadedPromotionImage, setpreLoadedPromotionImage] = useState(true);
  const [preLoadedPromotionVideo, setpreLoadedPromotionVideo] = useState(true);
  const [AuthorName, setAuthorName] = useState('');
  const [videoConfigurationTitle, setvideoConfigurationTitle] = useState('');
  const [WelcomeVideo, setWelcomeVideo] = useState<any>('');
  const [WelcomeImage, setWelcomeImage] = useState<any>();
  const [WelcomeAuthorImage, setWelcomeAuthorImage] = useState<any>();
  const [WelcomeVideoToggle, setWelcomeVideoToggle] = useState(false);
  const [isDataModified, setIsDataModified] = useState(false);
  const [saveLocationPath, setSaveLocationPath] = useState<string>('');
  const [addTagspopUp, setaddTagspopUp] = useState(false);

  const fileInput: any = React.createRef();
  const fileInputGroupLogo: any = React.createRef();
  const fileInputGroupOverlayLogo: any = React.createRef();
  const fileInputBackGroundLogo: any = React.createRef();
  const fileInputPromotionImage: any = React.createRef();
  const fileInputPromotionVideo: any = React.createRef();
  const fileInputWelcomeImage: any = React.createRef();
  const fileInputWelcomeVideo: any = React.createRef();
  const fileInputWelcomeAuthor: any = React.createRef();
  const fileInputPoweredByHeader: any = React.createRef();
  const fileInputGroupBanner: any = React.createRef();

  const syncHubspot = (clientId: number) => {
    mutateSyncHubspot.mutate(clientId, {
      onSuccess: () => {
        toast.success('Hubspot Synced Successfully');
      },
      onError: (error: any) => {
        toast.error(error.response.data.description);
      },
    });
  };

  const onFileChange = async (event: any, name: string) => {
    try {
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();

        reader.onload = (e: any) => {
          //Set the Base64 string return from FileReader as source.
          if (name !== 'promotionVideo' && name !== 'welcomeVideo') {
            const image = new Image();
            image.src = e.target.result;

            //Validate the File Height and Width.
            image.onload = () => {
              // @ts-ignore
              const height = image.height;
              // @ts-ignore
              const width = image.width;
              if (name !== 'author') {
                if (name === 'poweredByHeader') {
                  setPoweredByHeaderImage(image.src);
                  setPreLoadedPoweredByHeaderImage(false);
                } else if (name === 'groupBanner') {
                  setGroupBannerImage(image.src);
                  setPreLoadedGroupBanner(false);
                } else if (height === 720 && width === 1280) {
                  if (name === 'grapic') {
                    setPreloadedImage(false);
                    setImgPreview(image.src);
                  } else if (name === 'logo') {
                    setPreLoadedLogoImage(false);
                    setGroupLogoImage(image.src);
                  } else if (name === 'overlay') {
                    setPreLoadedLogoOverImage(false);
                    setGroupLogoOverlayImage(image.src);
                  } else if (name === 'promotion') {
                    setpreLoadedPromotionImage(false);
                    setPromotionImage(image.src);
                  } else if (name === 'welcome') {
                    setpreLoadedWelcomeImage(false);
                    setWelcomeImage(image.src);
                  } else if (name === 'background-logo') {
                    setpreLoadedBackgroundLogoImage(false);
                    setBackgroundLogoImage(image.src);
                  }
                } else {
                  toast.error('Height and Width must be 1280  x 720 .');
                  return;
                }
              } else {
                if (height === 720 && width === 720 && name === 'author') {
                  setpreLoadedAuthorImage(false);
                  setWelcomeAuthorImage(image.src);
                } else {
                  toast.error('Height and Width must be 720  x 720 .');
                  return;
                }
              }
            };
          } else {
            if (name === 'promotionVideo') {
              setPromotionVideo(e.target.result);
              setpreLoadedPromotionVideo(false);
            } else if (name === 'welcomeVideo') {
              setWelcomeVideo(e.target.result);
              setpreLoadedWelcomeVideo(false);
            }
          }
        };
        reader.readAsDataURL(event.target.files[0]);
        setIsDataModified(true);
      }
    } catch (error) {
      console.error('err--', error);
    }
  };

  const hadleImageinput = (name: string) => {
    if (name === 'graphic') {
      fileInput.current.click();
    } else if (name === 'logo') {
      fileInputGroupLogo.current.click();
    } else if (name === 'overlay') {
      fileInputGroupOverlayLogo.current.click();
    } else if (name === 'promotion') {
      fileInputPromotionImage.current.click();
    } else if (name === 'promotionVideo') {
      fileInputPromotionVideo.current.click();
    } else if (name === 'welcome') {
      fileInputWelcomeImage.current.click();
    } else if (name === 'welcomeVideo') {
      fileInputWelcomeVideo.current.click();
    } else if (name === 'background-logo') {
      fileInputBackGroundLogo.current.click();
    } else if (name === 'author') {
      fileInputWelcomeAuthor.current.click();
    } else if (name === 'poweredByHeader') {
      fileInputPoweredByHeader.current.click();
    } else if (name === 'groupBanner') {
      fileInputGroupBanner.current.click();
    }
  }; // fileInputWelcomeAuthor

  const setToggle = (status: any, id: any) => {
    const updatedTags = tagstaus.map((item: any) => {
      if (item.id === id) {
        return { ...item, status };
      } else {
        return item;
      }
    });
    settagstaus(updatedTags);
    setIsDataModified(true);
  };

  const Validation = () => {
    if (
      !ClientName ||
      !ClientName.trim().length ||
      (videoConfigurationTitle?.length > 0 && videoConfigurationTitle.trim().length <= 0) ||
      (AuthorName?.length > 0 && AuthorName.trim().length <= 0)
    ) {
      seterror(true);
      return false;
    }
    seterror(false);
    return true;
  };

  useEffect(() => {
    dispatch(getClientDetailsSuccess({ ClientDetials: null }));
    const clientId = localStorage.getItem('clientid');
    dispatch(getClientDetailsRequest({ clientId }));
    dispatch(fetchTagsRequest({ include_hidden: true }));
    return () => {
      dispatch(postCreateClientSuccess({}));
      dispatch(postUpdateClientSuccess({}));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientId) {
      if (!props.client) {
        dispatch(getClientDetailsRequest({ clientId }));
        dispatch(fetchTagsRequest({ include_hidden: true }));
      }
      // dispatch(getClientDetailsRequest({ clientId }));
    }
  }, [clientId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientId && props.client && props.tags) {
      setIsDataModified(false);
      const clientDetails = props.client;
      setClientName(clientDetails.name);
      setResellerName(clientDetails.reseller);
      setContractDate(clientDetails.contract_date);
      setGoLiveDate(clientDetails.go_live_date);
      setClientCode(clientDetails.client_code);
      setIndustryVerticalCategory(clientDetails.industry_vertical_category);

      setactive(clientDetails.status);
      if (clientDetails.logo_file_url) {
        setImgPreview(clientDetails.logo_file_url);
        setPreloadedImage(true);
      } else {
        setImgPreview(null);
        setPreloadedImage(false);
      }
      if (clientDetails.powered_by_header_file_url) {
        setPoweredByHeaderImage(clientDetails.powered_by_header_file_url);
        setPreLoadedPoweredByHeaderImage(true);
      } else {
        setPoweredByHeaderImage(null);
        setPreLoadedPoweredByHeaderImage(false);
      }
      setPoweredByHeaderEnabled(clientDetails?.powered_by_header_enabled);
      if (clientDetails.group_banner_file_url) {
        setGroupBannerImage(clientDetails.group_banner_file_url);
        setPreLoadedGroupBanner(true);
      } else {
        setGroupBannerImage(null);
        setPreLoadedGroupBanner(false);
      }
      setGroupBannerEnabled(clientDetails?.group_banner_enabled);
      if (clientDetails.comms_logo_file_url) {
        setGroupLogoImage(clientDetails.comms_logo_file_url);
        setPreLoadedLogoImage(true);
      } else {
        setGroupLogoImage(null);
        setPreLoadedLogoImage(false);
      }
      if (clientDetails.logo_overlay_file_url) {
        setGroupLogoOverlayImage(clientDetails.logo_overlay_file_url);
        setPreLoadedLogoOverImage(true);
      } else {
        setGroupLogoOverlayImage(null);
        setPreLoadedLogoOverImage(false);
      }
      if (clientDetails?.video_configuration) {
        const data = clientDetails.video_configuration;
        setWelcomeImage(data.image_url);
        setpreLoadedWelcomeImage(data.image_url ? true : false);
        setWelcomeVideo(data.video_url);
        setpreLoadedWelcomeVideo(data.video_url ? true : false);
        setWelcomeAuthorImage(data.author_image_url);
        setpreLoadedAuthorImage(data.author_image_url ? true : false);
        setAuthorName(data.author_name);
        setvideoConfigurationTitle(data.title);
        setWelcomeVideoToggle(data.enabled);
      } else {
        setWelcomeImage(null);
        setpreLoadedWelcomeImage(false);
        setWelcomeVideo(null);
        setpreLoadedWelcomeVideo(false);
        setAuthorName('');
        setvideoConfigurationTitle('');
        setWelcomeAuthorImage(null);
        setWelcomeVideoToggle(false);
      }
      if (clientDetails?.promo_configuration) {
        const data = clientDetails.promo_configuration;
        setLandingPromo(data?.enabled);
        setPromotionImage(data.image_url);
        setpreLoadedPromotionImage(data.image_url ? true : false);
        setPromotionVideo(data.video_url);
        setpreLoadedPromotionVideo(data.video_url ? true : false);
        setPromotionType(data.type);
      } else {
        setLandingPromo(false);
        setPromotionImage(null);
        setpreLoadedPromotionImage(false);
        setPromotionVideo(null);
        setpreLoadedPromotionVideo(false);
      }
      setenableEligibility(!!!clientDetails?.eligibility_config?.hide_client_admin_eligibility);
      setMemberSelfEnroll(clientDetails?.member_self_enrollment);
      setIsTest(clientDetails?.is_test);
      setCrmSync(clientDetails?.crm_sync);
      setContractIncludesComms(clientDetails?.contract_includes_comms);
      if (clientDetails?.logo_background_file_url) {
        setBackgroundLogoImage(clientDetails?.logo_background_file_url);
        setpreLoadedBackgroundLogoImage(true);
      } else setBackgroundLogoImage(null);
      if (clientDetails.landing_page_content) {
        const contentBlock = htmlToDraft(clientDetails.landing_page_content);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          setrichText(editorState);
        }
      }
      const tagsArr: any[] = props.tags?.map((item: any) => {
        if (clientDetails?.allowed_listener_tags?.includes(item.id)) {
          return { ...item, status: true };
        }
        return { ...item, status: false };
      });
      settagstaus(tagsArr);
      setinternalNotes(clientDetails.notes);
    }
  }, [props.client, props.tags, clientId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.tags?.length > 0 && clientId === null) {
      const tagsArr: any[] = props.tags?.map((item: any) => {
        return { ...item, status: false };
      });
      settagstaus(tagsArr);
    }
  }, [props.tags, clientId]);

  const handleSave = () => {
    if (Validation()) {
      if (WelcomeVideoToggle && (!WelcomeVideo || !WelcomeImage)) {
        toast.error('please add welcome video & image');
        return;
      }
      const allowedTags = tagstaus
        .filter((item: any) => {
          if (item.status) return item.id;

          return false;
        })
        .map((item: any) => item.id);
      const payload = {
        name: ClientName?.trim(),
        status: active,
        notes: internalNotes,
        allowed_listener_tags: allowedTags,
        logo_file_url: !preloadedImage ? image : image === null ? null : undefined,
        powered_by_header_file_url: !preLoadedPoweredByHeaderImage
          ? poweredByHeaderImage
          : poweredByHeaderImage === null
            ? null
            : undefined,
        powered_by_header_enabled: poweredByHeaderEnabled,
        group_banner_file_url: !preLoadedGroupBanner ? groupBannerImage : groupBannerImage === null ? null : undefined,
        group_banner_enabled: groupBannerEnabled,
        eligibility_config: {
          hide_client_admin_eligibility: !enableEligibility,
        },
        comms_logo_file_url: !preLoadedLogoImage ? groupLogoImage : groupLogoImage === null ? null : undefined,
        logo_overlay_file_url: !preLoadedLogoOverImage
          ? groupLogoOverlayImage
          : groupLogoOverlayImage === null
            ? null
            : undefined,
        promo_configuration: {
          enabled: LandingPromo,
          image_url: !preLoadedPromotionImage ? PromotionImage : PromotionImage === null ? null : undefined,
          type: PromotionType,
          video_url: !preLoadedPromotionVideo ? PromotionVideo : PromotionVideo === null ? null : undefined,
        },
        video_configuration: {
          enabled: WelcomeVideoToggle,
          image_url: !preLoadedWelcomeImage ? WelcomeImage : WelcomeImage === null ? null : undefined,
          video_url: !preLoadedWelcomeVideo ? WelcomeVideo : WelcomeVideo === null ? null : undefined,
          title: videoConfigurationTitle?.length > 0 ? videoConfigurationTitle.trim() : undefined,
          author_name: AuthorName?.length > 0 ? AuthorName.trim() : undefined,
          author_image_url: !preLoadedAuthorImage ? WelcomeAuthorImage : WelcomeAuthorImage === null ? null : undefined,
        },
        logo_background_file_url: !preLoadedBackgroundLogoImage
          ? BackgroundLogoImage
          : BackgroundLogoImage === null
            ? null
            : undefined,
        landing_page_content: richText ? draftToHtml(convertToRaw(richText.getCurrentContent())) : undefined,

        member_self_enrollment: memberSelfEnroll,
        crm_sync: crmSync,
        contract_includes_comms: contractIncludesComms,
        reseller: resellerName?.trim(),
        contract_date: contractDate || undefined,
        go_live_date: goLiveDate || undefined,
        is_test: isTest,
        client_code: clientCode || undefined,
        industry_vertical_category: industryVerticalCategory ? industryVerticalCategory : null,
      };
      if (clientId) {
        updateClient.mutate(payload, {
          onSuccess: () => {
            setIsDataModified(false);
            saveLocationPath !== '' && history.push(saveLocationPath);
          },
          onError: (error: any) => {
            toast.error(error.response.data.description);
          },
        });
      } else {
        dispatch(postCreateClientRequest({ payload }));
      }
    }
  };

  useEffect(() => {
    if (props.createClient?.status === 200) {
      dispatch(
        getClientDetailsRequest({
          clientId: props.createClient.data.id,
        }),
      );
      saveLocationPath !== '' && history.push(saveLocationPath);
    }
  }, [props.createClient]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.updateClient?.status === 200) {
      dispatch(
        getClientDetailsRequest({
          clientId: props.updateClient.data.id,
        }),
      );
      saveLocationPath !== '' && history.push(saveLocationPath);
    }
  }, [props.updateClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClientName = (name: string) => {
    setClientName(name);
    if (props.client?.name !== name) {
      setIsDataModified(true);
    }
  };

  const handleClientCode = (clientCode: string) => {
    const noWhitespaceClientCode = clientCode.replace(/\s/g, '');
    setClientCode(noWhitespaceClientCode.replace(/\s/g, ''));
    if (props.client?.clientCode !== noWhitespaceClientCode) {
      setIsDataModified(true);
    }
  };

  const handleResellertName = (name: string) => {
    setResellerName(name);
    if (props.client?.reseller !== name) {
      setIsDataModified(true);
    }
  };

  const handleContractDate = (date: string) => {
    if (date === '') {
      setContractDate(null);
    } else {
      setContractDate(date);
    }
    if (props.client?.contract_date !== date) {
      setIsDataModified(true);
    }
  };

  const handleGoLiveDate = (date: string) => {
    if (date === '') {
      setGoLiveDate(null);
    } else {
      setGoLiveDate(date);
    }
    if (props.client?.go_live_date !== date) {
      setIsDataModified(true);
    }
  };

  const handleIndustryVerticalCategory = (category: string) => {
    if (category === '') {
      setIndustryVerticalCategory(null);
    } else {
      setIndustryVerticalCategory(category);
    }
    if (props.client?.industry_vertical_category !== category) {
      setIsDataModified(true);
    }
  };

  const handleLandingPromoType = (type: string) => {
    setPromotionType(type);
    if (props.client?.promo_configuration?.type !== type) {
      setIsDataModified(true);
    }
  };
  const handleLandingPageMessage = (messageType: string) => {
    setrichText(messageType);
    if (props.client?.landing_page_content !== messageType) {
      setIsDataModified(true);
    }
  };
  const handleVideoHostName = (hostName: string) => {
    setAuthorName(hostName);
    if (props.client?.video_configuration?.author_name !== hostName) {
      setIsDataModified(true);
    }
  };
  const handleVideoHostTitle = (hostTitle: string) => {
    setvideoConfigurationTitle(hostTitle);
    if (props.client?.video_configuration?.title !== hostTitle) {
      setIsDataModified(true);
    }
  };

  return (
    <div>
      <RouteLeavingGuard
        when={isDataModified}
        navigate={(path: string) => history.push(path)}
        shouldBlockNavigation={(location: any) => {
          if (location.pathname !== ROUTE_PATH.GROUPS_CONFIGURE) {
            return true;
          }
          return false;
        }}
        titleText={'Alert'}
        contentText={'You have unsaved changes. Do you want to leave this page and lose your changes?'}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Disregard changes'}
        confirmSaveButtonText="Continue and Save changes"
        handleContinueSaveChange={(path) => {
          handleSave();
          setSaveLocationPath(path);
          setIsDataModified(false);
        }}
      />
      <Modal
        isModel={addTagspopUp}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden border-4 shadow-xl transform transition-all sm:my-32 sm:align-middle sm:max-w-4xl sm:w-full">
            <div className="bg-white w-full px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="flex justify-between sticky top-0 z-50 bg-white border-b-4 pb-4 mb-4">
                    <h3 className="text-lg leading-6  text-gray-900 font-bold mb-3" id="modal-title">
                      Add Listener Tags
                    </h3>
                    <button
                      type="button"
                      className={'px-5 py-1 text-white rounded-full font-medium bg-gray-400 '}
                      onClick={() => {
                        setaddTagspopUp(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                  <div className="w-full flex-wrap space-x-2 space-y-2 text-sm -ml-2 overflow-auto max-h-96">
                    {tagstaus?.length > 0 &&
                      tagstaus
                        .sort((a: any, b: any) => a.name.localeCompare(b.name))
                        .map((item: any, index: number) => {
                          return (
                            <button
                              key={`status_${index}`}
                              className={`${
                                item.status ? ' bg-citrus ' : ' bg-gray-background-light '
                              } text-black rounded-md text-sm font-bold ${index === 0 ? ' ml-2 mt-2 ' : ' '}`}
                              onClick={() => {
                                setToggle(!item.status, item.id);
                              }}
                            >
                              <div className="flex px-3 py-2">
                                <div className="grow  pr-2">{item.name}</div>
                              </div>
                            </button>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      {props.pending && <Spinner />}
      <Header userName={''} heading={<HeadBack clientName={ClientName} />} />
      <SwitchBar
        heading={HeaderOptions}
        position={1}
        isDisabled={clientId ? false : true}
        optionalButton={
          <div className="w-1/12">
            <SaveButton onSave={handleSave} disable={!isDataModified} />
          </div>
        }
      />
      <div className="max-window-height pb-32 pt-4 overflow-y-auto">
        <div className="flex justify-between">
          <div className="w-1/2 px-7 flex gap-4">
            <div className="py-4 w-1/2">
              <CustomInput
                inputType={'text'}
                value={ClientName}
                setValue={handleClientName}
                Label={'GROUP NAME'}
                Error={error && (!ClientName || !ClientName.trim().length)}
                ErrorMessage={'Field is Required'}
                labelClass="font-bold"
              />
            </div>
            <div className="py-4 w-1/2">
              <CustomInput
                inputType={'text'}
                value={resellerName}
                setValue={handleResellertName}
                Label={'RESELLER NAME'}
                Error={error && (!resellerName || !resellerName.trim().length)}
                ErrorMessage={'Field is Required'}
                labelClass="font-bold"
              />
            </div>
          </div>
          <div className="w-1/2 px-7">
            <p className="py-2 font-bold text-gray-dark">STATUS</p>
            <div className="w-full flex justify-start gap-x-4">
              {clientStatus.map((item: any) => (
                <button
                  key={item.key}
                  className={
                    'px-5 py-1 text-white rounded-full font-medium' +
                    (item.key === active
                      ? item.label === 'SETUP'
                        ? ' stacked-bar-orange-primary '
                        : item.label === 'INACTIVE'
                          ? ' stacked-bar-red-primary '
                          : ' stacked-bar-green-primary '
                      : ' bg-gray-300 ')
                  }
                  onClick={() => {
                    if (item.key !== props.client?.status) {
                      setIsDataModified(true);
                    }
                    setactive(item.key);
                  }}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="w-1/2 px-7 flex gap-4">
          <div className="py-4 w-1/2">
            <CustomInput
              inputType={'date'}
              value={contractDate}
              setValue={handleContractDate}
              Label={'CONTRACT DATE'}
              Error={error && (!contractDate || !contractDate.trim().length)}
              ErrorMessage={'Field is Required'}
              labelClass="font-bold"
            />
          </div>
          <div className="py-4 w-1/2">
            <CustomInput
              inputType={'date'}
              value={goLiveDate}
              setValue={handleGoLiveDate}
              Label={'GO LIVE DATE'}
              Error={error && (!goLiveDate || !goLiveDate.trim().length)}
              ErrorMessage={'Field is Required'}
              labelClass="font-bold"
            />
          </div>
        </div>
        <div className="w-1/2 px-7 flex gap-4">
          <div className="py-4 w-1/2">
            <CustomInput
              inputType={'text'}
              value={clientCode}
              setValue={handleClientCode}
              Label={'GROUP CODE'}
              labelClass="font-bold"
            />
          </div>
          <div className="py-4 w-1/2">
            <CustomInput
              inputType={'text'}
              value={industryVerticalCategory}
              setValue={handleIndustryVerticalCategory}
              Label={'Industry / Vertical'}
              labelClass="font-bold"
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-1/2 px-7">
            <p className="py-2 font-bold text-gray-dark">GLOBAL GRAPHICS</p>
            <hr className="bg-gray-dark"></hr>
            <p className="pt-1 font-normal text-gray-dark">GROUP GRAPHIC</p>
            <p className="pt-1 py-2 font-normal text-gray-dark">Displays as the provider of the benefit</p>
            <div className="w-full flex">
              <div
                className={`${
                  !image ? 'cursor-pointer' : ''
                } bg-gray-background-light rounded-sm  w-full aspect-ratio-container`}
                onClick={() => hadleImageinput('graphic')}
              >
                {image ? (
                  <div className="aspect-ratio-div">
                    <img className=" aspect-ratio-container-img " src={image} alt="clientsImage" />
                  </div>
                ) : (
                  <div className="h-56 flex justify-center items-center">
                    <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center ">
                      +
                    </button>
                    <input
                      ref={fileInput}
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => onFileChange(e, 'grapic')}
                      onClick={() => {
                        fileInput.current.value = null;
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="pl-4">
                <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                <div className="w-full aspect-ratio-container pt-2">
                  {image && (
                    <button
                      className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                      onClick={() => {
                        setImgPreview(null);
                        setIsDataModified(true);
                      }}
                    >
                      REMOVE
                    </button>
                  )}
                </div>
              </div>
            </div>
            <p className="pt-10 font-normal text-gray-dark">GROUP LOGO</p>
            <p className="pt-1 py-2 font-normal text-gray-dark">
              Displays in comms and MWA tiles for group affiliated content
            </p>
            <div className="w-full flex">
              <div
                className={`${
                  groupLogoImage ? '' : 'cursor-pointer'
                } bg-gray-background-light  rounded-sm  w-full  aspect-ratio-container`}
                onClick={() => hadleImageinput('logo')}
              >
                {groupLogoImage ? (
                  <div className="aspect-ratio-div">
                    <img className=" aspect-ratio-container-img " src={groupLogoImage} alt="groupLogoImage" />
                  </div>
                ) : (
                  <div className="h-56 flex justify-center items-center">
                    <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                      +
                    </button>
                    <input
                      ref={fileInputGroupLogo}
                      id="file-upload-group-logo"
                      type="file"
                      className="hidden"
                      onChange={(e) => onFileChange(e, 'logo')}
                      accept="image/*"
                      onClick={() => {
                        fileInputGroupLogo.current.value = null;
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="pl-4">
                <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                <div className="w-full aspect-ratio-container pt-2">
                  {groupLogoImage && (
                    <div className="w-full aspect-ratio-container ">
                      <button
                        className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                        onClick={() => {
                          setGroupLogoImage(null);
                          setIsDataModified(true);
                        }}
                      >
                        REMOVE
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-1/2 px-7 ">
            <div className="flex justify-between">
              <p className="py-2  text-gray-dark text-left">For Testing:</p>
              <ToggleButton
                id={'isTest'}
                setStatus={(e) => {
                  setIsTest(e);
                  setIsDataModified(true);
                }}
                status={isTest}
              />
            </div>
            <div className="flex justify-between">
              <p className="py-2  text-gray-dark text-left">Enable Client Admin Eligibility Access</p>
              <ToggleButton
                id={'EligibilityAccess'}
                setStatus={(e) => {
                  setenableEligibility(e);
                  setIsDataModified(true);
                }}
                status={enableEligibility}
              />
            </div>
            <div className="flex justify-between pt-2">
              <p className="py-2  text-gray-dark text-left">Enable Client for Member Self Enroll</p>
              <ToggleButton
                id={'self-enroll'}
                setStatus={(e) => {
                  setMemberSelfEnroll(e);
                  setIsDataModified(true);
                }}
                status={memberSelfEnroll}
              />
            </div>
            <div className="flex justify-between pt-2">
              <p className="py-2  text-gray-dark text-left">Enable CRM Sync</p>
              <ToggleButton
                id={'crm-sync'}
                setStatus={(e) => {
                  setCrmSync(e);
                  setIsDataModified(true);
                }}
                status={crmSync}
              />
            </div>
            <div className="flex justify-between pt-2">
              <p className="py-2  text-gray-dark text-left">Contract Includes Comms</p>
              <ToggleButton
                id={'contract-includes-comms'}
                setStatus={(e) => {
                  setContractIncludesComms(e);
                  setIsDataModified(true);
                }}
                status={contractIncludesComms}
              />
            </div>
            <div className="flex justify-between">
              <p className="py-2 font-bold text-gray-dark text-left">REQUIRED LISTENER TAGS</p>
              <button
                className="py-2 font-bold text-right text-blue-primary "
                onClick={() => {
                  setaddTagspopUp(true);
                }}
              >
                + Add
              </button>
            </div>
            <hr className="bg-gray-dark"></hr>
            <div className="w-full flex-wrap space-x-2 space-y-2 text-sm -ml-2 ">
              {tagstaus?.length > 0 && tagstaus.filter((tag: any) => tag.status).length > 0 ? (
                tagstaus
                  .filter((tag: any) => tag.status)
                  .map((item: any, index: number) => {
                    return (
                      <button
                        className={`bg-citrus text-white rounded-md text-sm font-bold ${
                          index === 0 ? 'ml-2 mt-2' : ''
                        }`}
                      >
                        <div className="flex px-3 py-2">
                          <div className="grow  pr-2">{item.name}</div>
                          <div
                            className="grow-0 "
                            onClick={() => {
                              setToggle(false, item.id);
                            }}
                          >
                            x
                          </div>
                        </div>
                      </button>
                    );
                  })
              ) : (
                <p className="m-2"> No Listener Tags </p>
              )}
            </div>
            <p className="py-2 pt-4 font-bold text-gray-dark text-left">Peer Group Link</p>
            <hr className="bg-gray-dark"></hr>
            <div
              title="copy"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_LISTENER_LINK}/onboarding/client-route/${clientId}/${encodeURI(ClientName)}`,
                );
                toast.success('Link Copied');
              }}
              className="w-full pt-4 flex-wrap space-x-2 space-y-2 cursor-pointer"
            >
              {process.env.REACT_APP_LISTENER_LINK}/onboarding/client-route/{clientId}/{encodeURI(ClientName)}
            </div>
            {crmSync && (
              <div>
                <p className="py-2 pt-4 font-bold text-gray-dark text-left">Sync with Hubspot</p>
                <hr className="bg-gray-dark"></hr>
                <Button className="mt-4" variant="primary" onClick={() => syncHubspot(Number(clientId))}>
                  Sync
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="w-full px-7 mt-5">
          <p className="py-2 font-bold text-gray-dark">LANDING PAGE</p>
          <hr className="bg-gray-dark"></hr>
          <div className="flex w-full">
            <div className="w-1/2">
              <p className="pt-4 font-medium text-gray-dark">LANDING HEADER BACKGROUND</p>
              <p className="py-1 pb-4 font-medium text-gray-dark">Displays at the top of the landing page</p>
              <div className="flex">
                <div
                  className={`${
                    BackgroundLogoImage ? '' : 'cursor-pointer'
                  } bg-gray-background-light  rounded-sm  w-full    aspect-ratio-container`}
                  onClick={() => hadleImageinput('background-logo')}
                >
                  {BackgroundLogoImage ? (
                    <div className="aspect-ratio-div">
                      <img
                        className=" aspect-ratio-container-img "
                        src={BackgroundLogoImage}
                        alt="BackgroundLogoImage"
                      />
                    </div>
                  ) : (
                    <div className="h-56 flex justify-center items-center">
                      <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                        +
                      </button>
                      <input
                        ref={fileInputBackGroundLogo}
                        id="file-upload-group-logo"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => onFileChange(e, 'background-logo')}
                        onClick={() => {
                          fileInputBackGroundLogo.current.value = null;
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="pl-4">
                  <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                  {BackgroundLogoImage && (
                    <div className=" w-full  aspect-ratio-container ">
                      <button
                        className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                        onClick={() => {
                          setBackgroundLogoImage(null);
                          setIsDataModified(true);
                        }}
                      >
                        REMOVE
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <p className="pt-4 font-medium text-gray-dark">LANDING HEADER LOGO</p>
              <p className="pt-1 pb-4 font-medium text-gray-dark">Displays on top of the Header Background</p>
              <div className="flex">
                <div
                  className={`${
                    groupLogoOverlayImage ? '' : 'cursor-pointer'
                  } bg-gray-background-light  rounded-sm  w-full    aspect-ratio-container`}
                  onClick={() => hadleImageinput('overlay')}
                >
                  {groupLogoOverlayImage ? (
                    <div className="aspect-ratio-div">
                      <img
                        className=" aspect-ratio-container-img "
                        src={groupLogoOverlayImage}
                        alt="groupLogoOverlayImage"
                      />
                    </div>
                  ) : (
                    <div className="h-56 flex justify-center items-center">
                      <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                        +
                      </button>
                      <input
                        ref={fileInputGroupOverlayLogo}
                        id="file-upload-group-overlay-logo"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => onFileChange(e, 'overlay')}
                        onClick={() => {
                          fileInputGroupOverlayLogo.current.value = null;
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="pl-4">
                  <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                  <div className="w-full aspect-ratio-container pt-2">
                    {groupLogoOverlayImage && (
                      <div className=" w-full aspect-ratio-container ">
                        <button
                          className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                          onClick={() => {
                            setGroupLogoOverlayImage(null);
                            setIsDataModified(true);
                          }}
                        >
                          REMOVE
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between items-center mt-6 ">
            <p className="pt-4 font-bold text-gray-dark">LANDING PROMO </p>
            <div>
              <ToggleButton
                id={'WelcomeVideoToggle'}
                setStatus={(e) => {
                  setLandingPromo(e);
                  setIsDataModified(true);
                }}
                status={LandingPromo}
              />
            </div>
          </div>
          <hr className="bg-gray-dark"></hr>
          <div className={!LandingPromo ? ' bg-white opacity-50 pointer-events-none cursor-not-allowed ' : ''}>
            <div className="w-full flex justify-between mt-6">
              <div className="w-1/2">
                <DropDown
                  Label={'LANDING PROMO TYPE'}
                  labelClass="font-medium text-normal"
                  setValue={handleLandingPromoType}
                  value={PromotionType}
                >
                  <option value={'image'}>Image</option>
                  <option value={'video'}>Video</option>
                </DropDown>
              </div>
            </div>
            <div className="flex w-full ">
              <div className="w-1/2">
                <p className="pt-4 font-medium text-gray-dark">
                  {PromotionType === 'video' ? 'VIDEO THUMBNAIL' : 'LANDING PROMO IMAGE'}
                </p>
                <p className="py-1 pb-4 font-medium text-gray-dark">Displays on the Landing Page</p>
                <div className="flex">
                  <div
                    className={`${
                      PromotionImage ? '' : 'cursor-pointer'
                    } bg-gray-background-light  rounded-sm  w-full    aspect-ratio-container`}
                    onClick={() => hadleImageinput('promotion')}
                  >
                    {PromotionImage ? (
                      <div className="aspect-ratio-div">
                        <img className=" aspect-ratio-container-img " src={PromotionImage} alt="PromotionImage" />
                      </div>
                    ) : (
                      <div className="h-56 flex justify-center items-center">
                        <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                          +
                        </button>
                        <input
                          ref={fileInputPromotionImage}
                          id="file-upload-group-promotion-image"
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => onFileChange(e, 'promotion')}
                          onClick={() => {
                            fileInputPromotionImage.current.value = null;
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="pl-4">
                    <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                    {PromotionImage && (
                      <div className=" w-full aspect-ratio-container ">
                        <button
                          className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                          onClick={() => {
                            setPromotionImage(null);
                            setIsDataModified(true);
                          }}
                        >
                          REMOVE
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {PromotionType === 'video' && (
                <div className="w-1/2">
                  <p className="pt-4 font-medium text-gray-dark">LANDING PROMO VIDEO</p>
                  <p className="py-1 pb-4 font-medium text-gray-dark">Displays on the Landing Page</p>
                  <div className="flex">
                    <div
                      className={`${
                        PromotionVideo ? '' : 'cursor-pointer'
                      } bg-gray-background-light rounded-sm w-full aspect-ratio-container `}
                      onClick={() => hadleImageinput('promotionVideo')}
                    >
                      {PromotionVideo ? (
                        <div>
                          <video width="100%" controls>
                            <source src={PromotionVideo} />
                            Your browser does not support HTML5 video.
                          </video>
                        </div>
                      ) : (
                        <div className="h-56 flex justify-center items-center ">
                          <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                            +
                          </button>
                          <input
                            ref={fileInputPromotionVideo}
                            id="file-upload-promotion-overlay-video"
                            accept="video/mp4,video/x-m4v,video/*"
                            type="file"
                            className="hidden"
                            onChange={(e) => onFileChange(e, 'promotionVideo')}
                            onClick={() => {
                              fileInputPromotionVideo.current.value = null;
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="pl-4">
                      {PromotionVideo && (
                        <div className=" w-full aspect-ratio-container ">
                          <button
                            className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                            onClick={() => {
                              setPromotionVideo(null);
                              setIsDataModified(true);
                            }}
                          >
                            REMOVE
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-4 w-full">
            <p className="pt-4 font-bold text-gray-dark">LANDING PAGE MESSAGE</p>
            <div className="mt-4">
              <RichTextEditor RichText={richText} setRichText={handleLandingPageMessage} />
            </div>
          </div>
        </div>
        <div className="w-full mt-8 px-7">
          <div className="w-full flex justify-between items-center">
            <p className="pt-4 font-bold text-gray-dark">WELCOME VIDEO</p>
            <div>
              <ToggleButton
                id={'WelcomeVideoToggle'}
                setStatus={(e) => {
                  setWelcomeVideoToggle(e);
                  setIsDataModified(true);
                }}
                status={WelcomeVideoToggle}
              />
            </div>
          </div>
          <hr className="bg-gray-dark"></hr>
          <div className="relative">
            {!WelcomeVideoToggle && <div className="w-full h-full absolute z-50 bg-white opacity-50"></div>}
            <div className="w-full flex">
              <div className="w-1/2">
                <p className="pt-4 pb-4 font-medium text-gray-dark">WELCOME VIDEO THUMBNAIL</p>
                <div className="flex">
                  <div
                    className={`${
                      WelcomeImage ? '' : 'cursor-pointer'
                    } bg-gray-background-light  rounded-sm  w-full aspect-ratio-container`}
                    onClick={() => hadleImageinput('welcome')}
                  >
                    {WelcomeImage ? (
                      <div className="aspect-ratio-div">
                        <img className=" aspect-ratio-container-img " src={WelcomeImage} alt="welcomeImage" />
                      </div>
                    ) : (
                      <div className="h-56 flex justify-center items-center">
                        <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                          +
                        </button>
                        <input
                          ref={fileInputWelcomeImage}
                          id="file-upload-group-promotion-image"
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => onFileChange(e, 'welcome')}
                          onClick={() => {
                            fileInputWelcomeImage.current.value = null;
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="pl-4">
                    <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                    {WelcomeImage && (
                      <div className=" w-full    aspect-ratio-container ">
                        <button
                          className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                          onClick={() => {
                            setWelcomeImage(null);
                            setIsDataModified(true);
                          }}
                        >
                          REMOVE
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <p className="pt-4 pb-4 font-medium text-gray-dark">WELCOME VIDEO FILE</p>
                <div className="flex">
                  <div
                    className={`${
                      WelcomeVideo ? '' : 'cursor-pointer'
                    } bg-gray-background-light  rounded-sm  w-full  aspect-ratio-container`}
                    onClick={() => hadleImageinput('welcomeVideo')}
                  >
                    {WelcomeVideo ? (
                      <div className="">
                        <video width="100%" controls>
                          <source src={WelcomeVideo} />
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                    ) : (
                      <div className="h-56  w-full flex justify-center items-center">
                        <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                          +
                        </button>
                        <input
                          ref={fileInputWelcomeVideo}
                          id="file-upload-promotion-overlay-video"
                          accept="video/mp4,video/x-m4v,video/*"
                          type="file"
                          className="hidden"
                          onChange={(e) => onFileChange(e, 'welcomeVideo')}
                          onClick={() => {
                            fileInputWelcomeVideo.current.value = null;
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="pl-4">
                    {WelcomeVideo && (
                      <div className=" w-full    aspect-ratio-container ">
                        <button
                          className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                          onClick={() => {
                            setWelcomeVideo(null);
                            setIsDataModified(true);
                          }}
                        >
                          REMOVE
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex">
              <div className="w-1/2">
                <p className="pt-4 pb-4 font-medium text-gray-dark">VIDEO HOST IMAGE</p>
                <div className="flex">
                  <div
                    className={`${
                      WelcomeAuthorImage ? '' : 'cursor-pointer'
                    } bg-gray-background-light rounded-sm w-56 h-56 aspect-ratio-container`}
                    onClick={() => hadleImageinput('author')}
                  >
                    {WelcomeAuthorImage ? (
                      <div className="aspect-ratio-div">
                        <img
                          className=" aspect-ratio-container-img "
                          src={WelcomeAuthorImage}
                          alt="groupLogoOverlayImage"
                        />
                      </div>
                    ) : (
                      <div className="h-56 flex justify-center items-center">
                        <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                          +
                        </button>
                        <input
                          ref={fileInputWelcomeAuthor}
                          id="file-upload-group-author-logo"
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => onFileChange(e, 'author')}
                          onClick={() => {
                            fileInputWelcomeAuthor.current.value = null;
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="pl-4">
                    <p className="pt-4 font-normal text-gray-dark">720 x 720 px</p>
                    {WelcomeAuthorImage && (
                      <div className=" w-full    aspect-ratio-container ">
                        <button
                          className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                          onClick={() => {
                            setWelcomeAuthorImage(null);
                            setIsDataModified(true);
                          }}
                        >
                          REMOVE
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="mt-14">
                  <CustomInput
                    inputType={'text'}
                    value={AuthorName}
                    setValue={handleVideoHostName}
                    Label={'VIDEO HOST NAME'}
                    labelClass="font-medium"
                    disabled={!WelcomeVideoToggle ? true : false}
                    Error={error && AuthorName?.length > 0 && AuthorName.trim().length <= 0}
                    ErrorMessage={'Field is Required'}
                  />
                </div>
                <div className="mt-4">
                  <CustomInput
                    inputType={'text'}
                    value={videoConfigurationTitle}
                    setValue={handleVideoHostTitle}
                    disabled={!WelcomeVideoToggle ? true : false}
                    Label={'VIDEO HOST TITLE'}
                    labelClass="font-medium"
                    Error={error && videoConfigurationTitle?.length > 0 && videoConfigurationTitle.trim().length <= 0}
                    ErrorMessage={'Field is Required'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-4 px-7">
          <div className="w-full flex justify-between items-center mt-6 ">
            <p className="pt-4 font-bold text-gray-dark">POWERED BY HEADER </p>
            <div>
              <ToggleButton
                id={'PoweredByToggle'}
                setStatus={(e) => {
                  setPoweredByHeaderEnabled(e);
                  setIsDataModified(true);
                }}
                status={poweredByHeaderEnabled}
              />
            </div>
          </div>
          <hr className="bg-gray-dark"></hr>
          <div
            className={`pt-4 ${!poweredByHeaderEnabled ? 'bg-white opacity-50 pointer-events-none cursor-not-allowed' : ''}`}
          >
            <p className="py-2 text-red">
              Disclaimer: The logo this image will replace is 180px by 36px, a 5:1 ratio. Upload an image with those
              dimensions for best results
            </p>
            <p className="pt-1 font-normal text-gray-dark">HEADER IMAGE</p>
            <p className="pt-1 py-2 font-normal text-gray-dark">Replaces the Kindly Human Header when active</p>
            <div className="w-1/2 flex">
              <div
                className={`${
                  !poweredByHeaderImage ? 'cursor-pointer bg-gray-background-light' : ''
                } rounded-sm w-full h-56 relative`}
                onClick={() => hadleImageinput('poweredByHeader')}
              >
                {poweredByHeaderImage ? (
                  <div className="w-full h-full">
                    <img className="w-auto h-56 object-cover" src={poweredByHeaderImage} alt="poweredByHeaderImage" />
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                      +
                    </button>
                    <input
                      ref={fileInputPoweredByHeader}
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => onFileChange(e, 'poweredByHeader')}
                      onClick={() => {
                        fileInputPoweredByHeader.current.value = null;
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="pl-4">
                <div className="w-full pt-2">
                  {poweredByHeaderImage && (
                    <button
                      className="bg-gray-300 rounded-full text-center text-sm text-white p-2 w-full my-2"
                      onClick={() => {
                        setPoweredByHeaderImage(null);
                        setIsDataModified(true);
                      }}
                    >
                      REMOVE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-4 px-7">
          <div className="w-full flex justify-between items-center mt-6 ">
            <p className="pt-4 font-bold text-gray-dark">Group Banner </p>
            <div>
              <ToggleButton
                id={'GroupBannerToggle'}
                setStatus={(e) => {
                  setGroupBannerEnabled(e);
                  setIsDataModified(true);
                }}
                status={groupBannerEnabled}
              />
            </div>
          </div>
          <hr className="bg-gray-dark"></hr>
          <div
            className={`pt-4 ${!groupBannerEnabled ? 'bg-white opacity-50 pointer-events-none cursor-not-allowed' : ''}`}
          >
            <p className="py-2 text-red">
              Disclaimer: The banner logo demensions are not validated. the image ratio on the banner is 3:1. Upload an
              image with that ratio for best results
            </p>
            <p className="py-3 font-normal text-gray-dark">Group Banner Image</p>
            <div className="w-1/2 flex">
              <div
                className={`${
                  !groupBannerImage ? 'cursor-pointer bg-gray-background-light' : ''
                } rounded-sm w-full h-56 relative`}
                onClick={() => hadleImageinput('groupBanner')}
              >
                {groupBannerImage ? (
                  <div className="w-full h-full">
                    <img className="rounded-lg" src={groupBannerImage} alt="poweredByHeaderImage" />
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                      +
                    </button>
                    <input
                      ref={fileInputGroupBanner}
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => onFileChange(e, 'groupBanner')}
                      onClick={() => {
                        fileInputGroupBanner.current.value = null;
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="pl-4">
                <div className="w-full pt-2">
                  {groupBannerImage && (
                    <button
                      className="bg-gray-300 rounded-full text-center text-sm text-white p-2 w-full my-2"
                      onClick={() => {
                        setGroupBannerImage(null);
                        setIsDataModified(true);
                      }}
                    >
                      REMOVE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-4 px-7">
          <p className="pt-4 font-bold text-gray-dark">INTERNAL NOTES</p>
          <hr className="bg-gray-dark"></hr>
          <div className="w-full pt-4">
            <textarea
              value={internalNotes}
              className="py-2 px-3 w-full rounded-md  h-28 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
              onChange={(e) => {
                setinternalNotes(e.target.value);
                setIsDataModified(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    client: state.Client.ClientDetials?.data || null,
    tags: state.Client.tags?.data || null,
    createClient: state.Client?.createClient || null,
    updateClient: state.Client?.updateClient || null,
    pending: state.Client?.pending || null,
  };
};

export default connect(mapStateToProps)(Configure);
