import { capitalize } from '@mui/material';
import { useState, useMemo, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { CustomInput, ToggleButton, UseFormDropDown } from '../../components/common';
import Modal from '../../components/common/modal';
import { onBlur } from '../../components/common/useForm';
import AlertBox from '../../components/route-leaving-prompt/alert-box';
import {
  ExternalProviderPlan,
  useUpdateExternalProviderPlan,
  useAddExternalProviderPlan,
} from '../../hooks/useExternalProviderPlans';

interface ExternalProviderPlanModalProps {
  plan: ExternalProviderPlan; // replace with your actual type
}

const ExternalProviderPlanModal: React.FC<ExternalProviderPlanModalProps> = ({ plan }) => {
  const { updateExternalProviderPlan } = useUpdateExternalProviderPlan(plan.id!);
  const { addExternalProviderPlan } = useAddExternalProviderPlan();
  const [modal, setModal] = useState<boolean>(false);
  const [alertModal, setAlertModal] = useState<boolean>(false);
  const [enableSaveOption, setEnableSaveOption] = useState(false);
  const defaultExternalProviderPlan = {
    ...plan,
    code: plan?.code || '',
    display_name: plan?.display_name || '',
    name: plan?.name || '',
    provider: plan?.provider || '',
    provider_types: plan?.provider_types || [],
    recuro_metatag3: plan?.recuro_metatag3 || '',
  };

  const providers = [
    { name: 'DialCare', value: 'dialcare' },
    { name: 'MeMD', value: 'me_md' },
    { name: 'Recuro', value: 'recuro' },
    { name: 'Opyn', value: 'opyn' },
  ];
  const providerPlans = ['counseling', 'primary', 'urgent', 'crisis', 'crisis_lite', 'rx', 'pediatric'];

  const handleSetStatus = (isChecked: boolean, type: string, field: any) => {
    field.onChange(isChecked ? [...field.value, type] : field.value.filter((value: string) => value !== type));
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      if (plan) {
        return defaultExternalProviderPlan;
      }
    }, [plan]),
  });

  useEffect(() => {
    if (plan.id) {
      reset(defaultExternalProviderPlan);
      setEnableSaveOption(false);
    }
  }, [plan]);

  const handleSave = (data: ExternalProviderPlan) => {
    console.log('called', data);
    if (plan.id !== undefined) {
      updateExternalProviderPlan.mutate(data);
      setModal(false);
      setAlertModal(false);
      setEnableSaveOption(false);
    } else {
      addExternalProviderPlan.mutate(data);
      setModal(false);
      setAlertModal(false);
      setEnableSaveOption(false);
      reset();
    }
  };

  return (
    <>
      <button
        onClick={(event) => {
          event.stopPropagation();
          setModal(true);
        }}
        className="text-center text-sm text-white px-2 py-2 bg-blue-primary rounded-sm "
      >
        {plan.id ? 'Edit' : 'Add Plan +'}
      </button>
      <Modal
        isModel={modal}
        InnerComponent={
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="inline-block align-bottom bg-white overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          >
            <form onSubmit={handleSubmit(handleSave)} id="external-provider-plan-form">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex justify-start">
                  <h3 className="text-2xl font-normal blue-primary py-1">{plan?.id ? 'Edit Plan' : 'Add Plan'}</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 gap-y-5 mr-5">
                  {/* NAME */}
                  <CustomInput
                    inputType="text"
                    Label="Name"
                    register={{
                      ...register('name', {
                        required: 'Please provide a name',
                        onBlur: (e: any) => onBlur(e, 'name', setValue),
                      }),
                    }}
                    Error={!!errors?.name?.message?.length}
                    ErrorMessage={errors.name?.message}
                  />
                  {/* CODE */}
                  <CustomInput
                    inputType="text"
                    Label="Code"
                    register={{
                      ...register('code', {
                        onBlur: (e: any) => onBlur(e, 'code', setValue),
                      }),
                    }}
                    Error={!!errors?.code?.message?.length}
                    ErrorMessage={errors.code?.message}
                  />
                  {/* DISPLAY NAME */}
                  <CustomInput
                    inputType="text"
                    Label="Display Name"
                    register={{
                      ...register('display_name', {
                        onBlur: (e: any) => onBlur(e, 'display_name', setValue),
                      }),
                    }}
                    Error={!!errors?.display_name?.message?.length}
                    ErrorMessage={errors.display_name?.message}
                  />
                  {/* TELEMED PROVIDER */}
                  <div className="flex flex-col">
                    <UseFormDropDown label={'Provider'}>
                      <select
                        className="select-box w-full"
                        defaultValue={plan?.provider}
                        {...register('provider', { required: 'Provider is required' })}
                      >
                        {providers.map((provider) => (
                          <option key={provider.value} value={provider.value}>
                            {provider.name}
                          </option>
                        ))}
                      </select>
                    </UseFormDropDown>
                    {errors.provider && (
                      <p className="block text-xs leading-4  font-medium text-red mb-2 pt-2">
                        {errors.provider.message}
                      </p>
                    )}
                  </div>
                  <div>
                    {/* EXTERNAL PROVIDER TYPE */}
                    <div className="block leading-4 text-gray-dark mb-2 text-left">Provider Type:</div>
                    {providerPlans.map((type, index) => (
                      <div key={type} className="text-base text-gray-dark flex">
                        <Controller
                          name="provider_types"
                          control={control}
                          rules={index === 0 ? { required: 'At least one provider type is required' } : {}}
                          render={({ field }) => (
                            <ToggleButton
                              setStatus={(isChecked) => handleSetStatus(isChecked, type, field)}
                              status={field.value.includes(type)}
                              id={type}
                            />
                          )}
                        />
                        <span>{capitalize(type)}</span>
                      </div>
                    ))}
                    {errors.provider_types && (
                      <p className="block text-xs leading-4  font-medium text-red mb-2 pt-2">
                        {errors.provider_types.message}
                      </p>
                    )}
                  </div>
                  {/* METATAG */}
                  <CustomInput
                    inputType="text"
                    Label="Recuro Metatag3"
                    register={{
                      ...register('recuro_metatag3', {
                        onBlur: (e: any) => onBlur(e, 'recuro_metatag3', setValue),
                      }),
                    }}
                    Error={!!errors?.recuro_metatag3?.message?.length}
                    ErrorMessage={errors.recuro_metatag3?.message}
                  />
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 gap-2 sm:px-6 sm:flex sm:flex-row-reverse items-center">
                <button
                  className={
                    'text-center h-8 text-sm text-white px-2 w-16 rounded-sm ' +
                    (isDirty || enableSaveOption ? 'bg-blue-primary' : 'bg-gray-200')
                  }
                  type="submit"
                  form="external-provider-plan-form"
                  disabled={!isDirty && !enableSaveOption}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="w-16 h-8 inline-flex items-center rounded-sm px-2 border rounded-sm text-center text-sm bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    if (isDirty || enableSaveOption) {
                      setAlertModal(true);
                    } else {
                      setModal(false);
                    }
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        }
      />
      <AlertBox
        visible={alertModal}
        handlePrompt={() => {
          reset(defaultExternalProviderPlan);
          setModal(false);
          setAlertModal(false);
          setEnableSaveOption(false);
        }}
        closeModal={() => {
          setAlertModal(false);
        }}
        confirmButtonText={'Discard Changes'}
        titleText={'Alert'}
        contentText={'You have unsaved changes. If you leave this screen without saving, your changes will be lost.'}
        cancelButtonText={'Cancel'}
      />
    </>
  );
};

export default ExternalProviderPlanModal;
