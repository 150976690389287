import { Route, Switch } from 'react-router';
import { Login } from '../containers';
import { publicRoutes, tokenErrorRoutes } from './public-router';
import useAuth from '../hooks/useAuth';

export const UnauthenticatedRouter = () => {
  const { tokenError } = useAuth();
  if (tokenError) {
    return (
      <>
        {tokenErrorRoutes.map((route) => (
          <Route path="*" component={route.component} />
        ))}
      </>
    );
  }

  // These route are avaiable ONLY to unauthenticated users
  return (
    // Routes here anyone could see
    <Switch>
      {publicRoutes.map((route) => (
        <Route path={route.path} component={route.component} />
      ))}
      {/* This works similarly to a `&next='path'` pattern; renders any other url as the login page.
      Once you login, you're authenticated, and it sends you where you belong. Might be worth trying to replace MWA next pattern with this. */}
      <Route component={Login} path="*" />
    </Switch>
  );
};
